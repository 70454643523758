import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import { useReviewed } from "../../hooks/useReviewed";
import { ConfirmationsSummaryStyled, InfoBlockStyled } from "./ConfirmationsSummary.styled";

const ConfirmationsSummary = (props) => {
  const { reviewed } = useReviewed();
  const news = props.confirmations.length - reviewed.length;

  return (
    <section>
      <ConfirmationsSummaryStyled>
        <InfoBlockStyled>
          <span>NUEVAS</span>
          <span>{props.isLoading ? <CircularProgress /> : news}</span>
        </InfoBlockStyled>
        <InfoBlockStyled>
          <span>TOTAL</span>
          <span>{props.isLoading ? <CircularProgress /> : props.confirmations.length}</span>
        </InfoBlockStyled>
      </ConfirmationsSummaryStyled>
    </section>
  );
};

ConfirmationsSummary.propTypes = {
  confirmations: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool.isRequired,
};

ConfirmationsSummary.defaultProps = {
  confirmations: [],
};

export default ConfirmationsSummary;
