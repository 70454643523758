import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Global, css } from "@emotion/react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";

const theme = createTheme({
  typography: {
    fontFamily: ["sans-serif"].join(","),
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className="app">
        <Global
          styles={css`
            html,
            body {
              background-color: #343434;
              margin: 0;
              padding: 0;
            }
          `}
        />
        <Router>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/dashboard" element={<Dashboard />} />
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
};

export default App;
