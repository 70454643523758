import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { useAuthentication } from "../hooks/useAuthentication";

const AppBar = () => {
  const { logout } = useAuthentication();

  return (
    <MuiAppBar position="static">
      <Toolbar>
        <Button align="right" color="inherit" onClick={logout}>
          Logout
        </Button>
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
