import { useState } from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import Button from "@mui/material/Button";
import DeleteModal from "./DeleteModal";
import { useConfirmations } from "../hooks/useConfirmations";
import { useReviewed } from "../hooks/useReviewed";
import { ConfirmationsTableStyled, ActionButtonsStyled } from "./ConfirmationsTable.styled";

const byDate = (a, b) => {
  if (a.created < b.created) {
    return 1;
  }
  if (a.created > b.created) {
    return -1;
  }
  return 0;
};

const ConfirmationsTable = (props) => {
  const { addReviewed, reviewed, removeReviewed } = useReviewed();

  const [deletingConfirmation, setDeletingConfirmation] = useState(null);
  const { removeConfirmation } = useConfirmations();

  const handleDelete = (confirmation) => {
    setDeletingConfirmation(confirmation);
  };

  const handleDeleteCancel = () => {
    setDeletingConfirmation(null);
  };

  const handleDeleteConfirm = () => {
    Promise.all([removeConfirmation(deletingConfirmation.id), removeReviewed(deletingConfirmation.id)]).finally(() => {
      setDeletingConfirmation(null);
    });
  };

  const handleViewed = (id) => {
    addReviewed(id);
  };

  return (
    <ConfirmationsTableStyled>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Enviado</TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Invitados</TableCell>
              <TableCell align="left">Intolerancias</TableCell>
              <TableCell align="center">Bus</TableCell>
              <Tooltip title="Madrid (Moncloa) - Iglesia">
                <TableCell align="center">
                  T1 <InfoIcon />
                </TableCell>
              </Tooltip>
              <Tooltip title="Iglesia - Finca">
                <TableCell align="center">
                  T2 <InfoIcon />
                </TableCell>
              </Tooltip>
              <Tooltip title="Finca - Iglesia">
                <TableCell align="center">
                  T3 <InfoIcon />
                </TableCell>
              </Tooltip>
              <Tooltip title="Iglesia - Madrid (Moncloa)">
                <TableCell align="center">
                  T4 <InfoIcon />
                </TableCell>
              </Tooltip>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.isLoading ? (
              <span>Cargando</span>
            ) : (
              props.confirmations.sort(byDate).map((row, idx) => {
                const isNew = !reviewed.includes(row.id);

                return (
                  <TableRow key={row.id} sx={{ backgroundColor: isNew ? "aquamarine" : "#fff" }}>
                    <TableCell>{props.confirmations.length - idx}</TableCell>
                    <TableCell component="th" scope="row">
                      {row.created.toLocaleString()}
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.companion}</TableCell>
                    <TableCell>{row.intolerances != null ? row.intolerances : ""}</TableCell>
                    <TableCell align="center">{row.bus ? "Sí" : "No"}</TableCell>
                    <TableCell align="center">{row.journeys[0] ? "✔️" : ""}</TableCell>
                    <TableCell align="center">{row.journeys[1] ? "✔️" : ""}</TableCell>
                    <TableCell align="center">{row.journeys[2] ? "✔️" : ""}</TableCell>
                    <TableCell align="center">{row.journeys[3] ? "✔️" : ""}</TableCell>
                    <TableCell>
                      <ActionButtonsStyled>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            handleDelete(row);
                          }}
                        >
                          Eliminar
                        </Button>
                        {isNew && (
                          <Button
                            variant="outlined"
                            onClick={() => {
                              handleViewed(row.id);
                            }}
                          >
                            Visto
                          </Button>
                        )}
                      </ActionButtonsStyled>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteModal
        isOpen={deletingConfirmation != null}
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        confirmation={deletingConfirmation}
      />
    </ConfirmationsTableStyled>
  );
};

ConfirmationsTable.propTypes = {
  confirmations: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool.isRequired,
};

ConfirmationsTable.defaultProps = {
  confirmations: [],
};

export default ConfirmationsTable;
