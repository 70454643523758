import styled from "@emotion/styled";

export const ConfirmationsSummaryStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const InfoBlockStyled = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border: 1px solid #343434;
  border-radius: 10%;
  padding: 1em;
  margin: 1em;

  & > span:nth-child(2) {
    font-size: 3em;
  }
`;
