import { useEffect, useState } from "react";
import { getDatabase, ref, child, remove, onValue } from "firebase/database";

const buildConfirmations = (raw) => {
  return Object.keys(raw).map((key) => ({
    id: key,
    name: raw[key].name,
    email: raw[key].email,
    bus: raw[key].bus === "yes",
    companion: raw[key].companion !== "" ? raw[key].companion : null,
    intolerances: raw[key].intolerances !== "" ? raw[key].intolerances : null,
    journeys: raw[key].journeys,
    created: new Date(raw[key].created),
  }));
};

export const useConfirmations = () => {
  const [confirmations, setConfirmations] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const db = getDatabase();
    const confirmationsRef = ref(db, "confirmations");
    onValue(
      confirmationsRef,
      (snapshot) => {
        setIsLoading(false);
        const result = snapshot.val();
        const results = result != null ? buildConfirmations(snapshot.val()) : [];
        setConfirmations(results);
      },
      (error) => {
        console.error("ERROR", error); // eslint-disable-line no-console
        setHasError(true);
      }
    );
  }, []);

  const removeConfirmation = (id) => {
    const dbRef = ref(getDatabase());
    return remove(child(dbRef, `confirmations/${id}`));
  };

  return { confirmations, isLoading, hasError, removeConfirmation };
};
