import { useEffect, useState, useContext } from "react";
import { getDatabase, ref, set, onValue, child, remove } from "firebase/database";
import { FirebaseContext } from "../contexts/FirebaseContext";

export const useReviewed = (uid) => {
  const { user } = useContext(FirebaseContext);
  const [reviewed, setReviewed] = useState([]);
  const [listenActive, setListenActive] = useState(false);
  const db = getDatabase();

  useEffect(() => {
    if (user != null && !listenActive) {
      const dbRef = ref(db, `reviewed/${user.uid}`);
      onValue(
        dbRef,
        (snapshot) => {
          const result = snapshot.val();
          const ids = Object.keys(result);
          setReviewed(ids);
        },
        (error) => {
          console.error(error); // eslint-disable-line no-console
        }
      );
      setListenActive(true);
    }
  }, [user, listenActive, db]);

  const addReviewed = (confirmationId) => {
    const dbRef = ref(db, `reviewed/${user.uid}/${confirmationId}`);
    set(dbRef, new Date().toISOString());
  };

  const removeReviewed = (confirmationId) => {
    const dbRef = ref(getDatabase());
    return remove(child(dbRef, `reviewed/${user.uid}/${confirmationId}`));
  };

  return { reviewed, addReviewed, removeReviewed };
};
