import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { FirebaseContext } from "../contexts/FirebaseContext";
import { useAuthentication } from "../hooks/useAuthentication";
import { LoginStyled, LoginButtonStyled } from "./Login.styled";

const Login = () => {
  const { user } = useContext(FirebaseContext);
  const { signInWithGoogle, logout } = useAuthentication();
  const navigate = useNavigate();

  useEffect(() => {
    if (user != null) {
      navigate("/dashboard");
    }
  }, [user, navigate]);

  return (
    <LoginStyled>
      <div className="login">
        <div className="login__container">
          {user != null ? (
            <button onClick={logout}>Logout</button>
          ) : (
            <LoginButtonStyled>
              <Button variant="outlined" onClick={signInWithGoogle}>
                Login
              </Button>
            </LoginButtonStyled>
          )}
        </div>
      </div>
    </LoginStyled>
  );
};

export default Login;
