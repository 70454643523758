import styled from "@emotion/styled";

export const LoginStyled = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

export const LoginButtonStyled = styled.div`
  & > button {
    color: #ffffff;
  }
`;
