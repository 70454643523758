import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const renderConfirmationInfo = (confirmation) => {
  return (
    <ul>
      <li>
        Nombre: <strong>{confirmation.name}</strong>
      </li>
      <li>
        Email: <strong>{confirmation.email}</strong>
      </li>
      <li>
        Creado: <strong>{confirmation.created.toLocaleString()}</strong>
      </li>
    </ul>
  );
};

const DeleteModal = (props) => (
  <div>
    <Dialog
      open={props.isOpen}
      onClose={props.onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Confirma la eliminación</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Vas a eliminar la siguiente confirmación:
          {props.confirmation != null && renderConfirmationInfo(props.confirmation)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel}>Cancelar</Button>
        <Button onClick={props.onConfirm} autoFocus>
          Eliminar
        </Button>
      </DialogActions>
    </Dialog>
  </div>
);

DeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  confirmation: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    created: PropTypes.string.isRequired,
  }).isRequired,
};

export default DeleteModal;
