import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FirebaseContext } from "../contexts/FirebaseContext";
import { useConfirmations } from "../hooks/useConfirmations";
import ConfirmationsSummary from "./ConfirmationsSummary/ConfirmationsSummary";
import ConfirmationsTable from "./ConfirmationsTable";
import AppBar from "./AppBar";

const Dashboard = () => {
  const { user } = useContext(FirebaseContext);
  const { confirmations, isLoading } = useConfirmations();
  const navigate = useNavigate();

  useEffect(() => {
    if (user == null) {
      navigate("/");
    }
  }, [user, navigate]);

  return (
    !isLoading &&
    user?.uid != null && (
      <div>
        <AppBar />
        <ConfirmationsSummary confirmations={confirmations} isLoading={isLoading} />
        <ConfirmationsTable confirmations={confirmations} isLoading={isLoading} />
      </div>
    )
  );
};

export default Dashboard;
