import styled from "@emotion/styled";

export const ConfirmationsTableStyled = styled.div`
  & > div {
    width: 100%;

    @media only screen and (min-width: 480px) {
      width: 90%;
    }
  }
`;
export const ActionButtonsStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;

  & > button:nth-child(2) {
    margin-left: 5px;
  }
`;
